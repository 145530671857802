import React, { useEffect, useMemo, useState } from "react";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import s from "./Datablock.InsiderTransactions.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const InsidersTransactions: React.FC<Props> = ({ locale, demo }) => {
  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "insider-positions",
        widgetId: "insider-positions",
        token: tokens.insiderPositions,
        label: locale === "en" ? "Positions" : "Positioner",
      },
      {
        widget: "insider-transactions",
        widgetId: "insider-transactions-buy-sell",
        token: tokens.insiderTransactionsBuySell,
        label: locale === "en" ? "Buy and sell" : "Köp och sälj",
      },
      {
        widget: "insider-transactions",
        widgetId: "insider-transactions-other",
        token: tokens.insiderTransactionsOther,
        label: locale === "en" ? "Other" : "Övrigt",
      },
    ],
    [locale]
  );

  const [activeTab, setActiveTab] = useState(widgetConfigs[0].widgetId);

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  return (
    <div className={ss.tabsContainer}>
      <div className={ss.tabsList} role="tablist">
        {widgetConfigs.map((config) => (
          <button
            key={config.widgetId}
            role="tab"
            aria-selected={activeTab === config.widgetId}
            aria-controls={`panel-${config.widgetId}`}
            id={`tab-${config.widgetId}`}
            className={cn(ss.tabButton, {
              [ss.active]: activeTab === config.widgetId,
            })}
            onClick={() => setActiveTab(config.widgetId)}
          >
            {config.label}
          </button>
        ))}
      </div>
      <div className={ss.tabPanels}>
        {widgetConfigs.map((config) => (
          <div
            key={config.widgetId}
            role="tabpanel"
            id={`panel-${config.widgetId}`}
            aria-labelledby={`tab-${config.widgetId}`}
            hidden={activeTab !== config.widgetId}
            className={ss.tabPanel}
          >
            <div
              id={`${config.widgetId}-${locale}`}
              className={cn(c.wide, s.insiderTransactions)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsidersTransactions;
