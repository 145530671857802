import React, { useEffect, useMemo } from "react";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import c from "../../../styles/container.module.scss";
import cn from "classnames";
import s from "./Datablock.Calendar.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const Calendar: React.FC<Props> = ({ locale, demo }) => {
  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "calendar-v2",
        widgetId: "calendar-v2",
        token: tokens.calender,
        label: locale === "en" ? "Calendar" : "Kalender",
      },
    ],
    [locale]
  );

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  return (
    <div className={s.calendarContainer}>
      {widgetConfigs.map((config) => (
        <div
          key={config.widgetId}
          id={`${config.widgetId}-${locale}`}
          className={cn(c.medium, s.calendar)}
        />
      ))}
    </div>
  );
};

export default Calendar;
