import React from "react";
import cn from "classnames";
import RichText from "../RichText";
import { isRichTextContent } from "../../../lib/storyblok/types/fieldtypes/richText";
import Markdown from "../../Markdown";
import s from "./BlockIntro.module.scss";
import c from "../../../styles/container.module.scss";
import s2 from "../../Editorial/components/EditorialCopy.module.scss";

const BlockIntro = ({
  alignment,
  style,
  title,
  description,
  buttons,
  featured,
  flexibleWidth,
}) => {
  const introStyle =
    style !== "white" &&
    style !== "gray" &&
    style !== "none" &&
    style !== "" &&
    style !== undefined
      ? s.inverted
      : s.default;
  const featuredStyle = featured ? s.featured : "";
  const widthStyle = flexibleWidth ? s.flexibleWidth : c.xWide;

  const renderDescription = () => {
    if (!description) return null;

    // Check if it's a rich text content
    if (isRichTextContent(description)) {
      return (
        <RichText
          content={description}
          className={s.description}
          alignment={alignment}
        />
      );
    }

    // Fallback to Markdown for string content
    return (
      <div className={cn(s.description)}>
        <Markdown>{description}</Markdown>
      </div>
    );
  };

  return (
    <div
      className={cn(
        s.intro,
        widthStyle,
        introStyle,
        featuredStyle,
        s2.copy,
        s[alignment]
      )}
    >
      {title && <h2 className={s.title}>{title}</h2>}
      {description && renderDescription()}
      <div className={cn(s.buttons)}>{buttons}</div>
    </div>
  );
};

export default BlockIntro;
