import LinkWrapper from "../../../components/Nestable/LinkWrapper/LinkWrapper";

type Props = {
  text: string;
  href: string;
};

const FooterLink = ({ text, href }: Props) => {
  return (
    <li>
      <LinkWrapper href={href} text={text} useComputedColor={false} />
    </li>
  );
};

export default FooterLink;
