import React, { useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import AccordionUi from "../../ui/Accordion";
import { AccordionBlock } from "../../lib/storyblok/types/blocks/AccordionBlock";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";

const Accordion: StoryblokBlockComponent<AccordionBlock> = ({
  title,
  description,
  buttons,
  innerSpacing,
  outerSpacing,
  width,
  style,
  preventExpandFirstItem = false,
  items,
}) => {
  const resolveLink = useLinks();
  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  const [expanded, setExpanded] = useState<string | false>(
    preventExpandFirstItem ? false : "panel0"
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <AccordionUi
      title={title}
      description={description}
      buttons={buttonCollection}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      style={style}
      width={width}
      expanded={expanded}
      handleChange={handleChange}
    >
      {items?.map((item, index) => (
        <AccordionUi.Item
          title={item.title}
          description={item.description}
          style={style}
          key={item._uid}
          index={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        />
      ))}
    </AccordionUi>
  );
};

export default Accordion;
